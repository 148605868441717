import { mapActions } from "vuex"
export default {
  name: 'Authorization',
  data () {
    return {
      login: null,
      rulesLogin: [
        value => !!value || 'Поле обов\'язкове',
        value => (value && value.length >= 3) || 'Мінімум 3 символа',
      ],
      showPassword: false,
      password: null,
      rulesPassword: [
        value => !!value || 'Поле обов\'язкове',
        value => (value && value.length >= 5) || 'Мінімум 5 символів',
      ],
    }
  },
  mounted () {
    if (localStorage.getItem('Token')) {
      this.$notification.info('Ви авторизовані')
      setTimeout(() => {this.$router.push({ name: 'home' })}, 1000)
    }
  },
  methods: {
    ...mapActions(["setAuth"]),
    setAuthorization () {
      const body = {
        username: this.login,
        password: this.password
      }
      this.setAuth(body).then((r) => !r && this.$notification.error(this.$i18n.t('notify.incorrectCredentials')))
    }
  }
}
